import './Modal.scss';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { prefixFactory } from 'icts-helpers';
import Icon from '../../icts-icon';

const prefix = prefixFactory('modal__wrapper--');
const sizes = prefix([
  'sm',
  'md',
  'lg',
]);

const Modal = ({
  handleClose,
  show,
  children,
  title,
  size,
  onShow,
}) => {
  function handleShow (e) {
    e.preventDefault();
    e.stopPropagation();

    onShow(! show);
  }

  const classes = classNames(
    'modal__wrapper',
    sizes[size],
  );

  return (
    <div
      className={`modal ${show ? 'modal--show' : ''}`}
      onChange={handleShow}
    >
      <section className={classes}>
        <div className="modal__header">
          <h2>{title}</h2>
          <button
            className="close"
            type="button"
            onClick={handleClose}
          >
            <Icon name="fechar" />
          </button>
        </div>
        <div className="modal__body">
          {children}
        </div>
      </section>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  size: PropTypes.string,
  onShow: PropTypes.func,
};

Modal.defaultProps = {
  title: '',
  size: 'sm',
  onShow: () => {},
};

export default Modal;
