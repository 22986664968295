import { useEffect, useState, useContext } from "react";
import { ScaffoldLayout, Row, Result } from "@icts/web";
import { Loading } from "@icts/icons";
import { InvoicesList } from "./components/InvoicesList";
import { PageTitle } from "./components/PageTitle";
import { useAtom } from "jotai";
import { companyAtom } from "../../store";
import { api } from "../../Services/api";
import { AuthContext } from "../../Contexts/auth";
import { color } from "@icts/tokens";
import './Home.scss'

export const Home = () => {
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const { user, logout } = useContext(AuthContext);
  const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL
  const [selectedCompany, setCompany] = useAtom(companyAtom);
  const [usingICTSModalForTaxes, setUsingICTSModalForTaxes] = useState(false);
  const [openRegisterInvoiceModalICTS, setOpenRegisterInvoiceModalICTS] =
    useState(false);

  useEffect(() => {
    (async () => {
      const cnpjs = await api.getUserCNPJs();

      if (cnpjs?.length > 0) {
        setCompany(cnpjs[0]);
        setCompanies(cnpjs);
      }

      setLoading(false);
    })();
  }, []);

  if (loading)
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Loading size={124} color={color.system.blue.pure} />
      </Row>
    );

  return (
    <ScaffoldLayout
      onLogout={() => logout()}
      display={{
        name: user.email,
        profile: companies?.length === 1 ? selectedCompany?.cnpj : "",
      }}
      topBarContent={
        import.meta.env.VITE_COMPANY_ENV === "ICTS" ? (
          <img src="/icts-brand.png"></img>
        ) : (
          <img src="/band-brand.png"></img>
        )
      }
      tenants={{
        onChange: (selectedCNPJ) => {
          const company = companies.find(({ cnpj }) => cnpj === selectedCNPJ);

          setCompany(company);
        },
        availableTenants: companies?.map((company) => {
          const customLabel = `${company?.cnpj} - ${company?.razao_social}`
          return {
            value: company?.cnpj,
            isActive: selectedCompany.cnpj === company?.cnpj,
            label: customLabel?.length > 30 ? `${customLabel.substring(0,30)}...` : customLabel,
          };
        }),
      }}
    >
      {selectedCompany?.cnpj ? (
        <>
          <PageTitle
            setUsingICTSModalForTaxes={setUsingICTSModalForTaxes}
            setOpenRegisterInvoiceModalICTS={setOpenRegisterInvoiceModalICTS}
          />

          <InvoicesList
            usingICTSModalForTaxes={usingICTSModalForTaxes}
            setUsingICTSModalForTaxes={setUsingICTSModalForTaxes}
            openRegisterInvoiceModalICTS={openRegisterInvoiceModalICTS}
            setOpenRegisterInvoiceModalICTS={setOpenRegisterInvoiceModalICTS}
          />
        </>
      ) : (
        <Result
          status="warning"
          title="Você não possui um CNPJ vinculado ao seu acesso"
          subTitle={
            import.meta.env.VITE_COMPANY_ENV === "BAND"
              ? `Entre em contato com o time de suporte e solicite a configuração da sua conta pelo e-mail ${supportEmail} ou pelos telefones (11) 3131-3452 / 3131-7584.`
              : `Entre em contato com o time de suporte e solicite a configuração da sua conta pelo e-mail ${supportEmail}.`
          }
        />
      )}
    </ScaffoldLayout>
  );
};
