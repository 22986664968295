import { ConfigProvider } from "@icts/web";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { AuthProvider } from "./Contexts/auth";
import { GlobalProvider } from "./Contexts/global";
import { color } from "@icts/tokens";
import { ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.min.css';

function App() {
  return (
    <ConfigProvider
      baseTheme="aliant"
      theme={{
        token: {
          colorPrimary: color.system.blue.dark,
          colorPrimaryHover: color.system.blue.pure,
          colorPrimaryActive: color.system.blue.pure,
          colorPrimaryText: color.neutral.light.pure,
        },
      }}
    >
      <Router>
        <GlobalProvider>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>

            <ToastContainer
              draggable
              rtl={false}
              closeOnClick
              pauseOnHover
              theme="light"
              pauseOnFocusLoss
              autoClose={3000}
              newestOnTop={false}
              position="top-right"
              hideProgressBar={false}
            />
          </AuthProvider>
        </GlobalProvider>
      </Router>
    </ConfigProvider>
  );
}

export default App;
