import removeAccents from 'remove-accents'
import currencyFormatter from 'currency-formatter'

const formatNumberToReal = (number, currCode = 'BRL') => {
  return currencyFormatter.format(Number(number), { code: currCode })
};

const formatRealToNumber = (val, currCode = 'BRL') => {
  return currencyFormatter.unformat(val, { code: currCode })
};

const formatStringForSearch = (string) => {
  if (!string) return string

  const newString = string.toString();
  return removeAccents(newString).toLowerCase();
};

export { formatNumberToReal, formatRealToNumber, formatStringForSearch };
