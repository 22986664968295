import React, { createContext, useCallback, useState } from 'react'

import Icon from 'icts-icon'
import Modal from 'icts-modal'
import PropTypes from 'prop-types'
import Spinner from 'icts-spinner'

export const GlobalContext = createContext({})

export const GlobalProvider = ({ children }) => {
  const [onClose, setOnClose] = useState(null)
  const [show, setShow] = useState(false)
  const [type, setType] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [_showSpinner, setShowSpinner] = useState(0)

  const showSpinner = useCallback(() => {
    setShowSpinner((old) => old + 1)
  }, [])

  const hideSpinner = useCallback(() => {
    setShowSpinner((old) => old - 1)
  }, [])

  const showModal = ({ type, title, description, onCloseFn }) => {
    setType(type)
    setTitle(title)
    setDescription(description)
    setShow(true)

    if (onCloseFn) {
      setOnClose({ fn: onCloseFn })
    } else {
      setOnClose(null)
    }
  }

  const closeModal = () => {
    setType('')
    setTitle('')
    setDescription('')
    setShow(false)
    onClose && onClose.fn()
  }

  return (
    <GlobalContext.Provider
      value={{ showModal, closeModal, showSpinner, hideSpinner }}
    >
      <Modal show={show} title="Alerta" size="sm" handleClose={closeModal}>
        <div className="modal__alert">
          {(type === 'erro' || type === 'alerta') && (
            <div className="modal__alert-icon">
              <Icon name={type} size="lg" />
            </div>
          )}
          {title && <h4>{title}</h4>}
          {description && <p>{description}</p>}
        </div>
      </Modal>
      {children}
      {_showSpinner > 0 && <Spinner />}
    </GlobalContext.Provider>
  )
}

GlobalProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
}
