import { FC, useEffect, useState, useRef } from "react";
import { Row, Col, Select, InputRef, InputNumber, Input, TextArea, Radio, Upload, Form, FormInstance, DatePicker } from "@icts/web";
import {
  formatNumberToReal,
  formatRealToNumber,
} from "../../../../../../../utils/format";
import { selectCurrencyOptions, disableDaysBeforeToday, disableDays15Days } from './consts'
import ContentLoader from "react-content-loader";
import { api } from "../../../../../../../Services/api";
import dayjs from "dayjs";
import { toast } from "react-toastify";

interface Props {
  currency: string;
  form?: FormInstance<any>;
  usingICTSModalForTaxes: boolean;
  setCurrency: (curr: string) => void;
  setValueInvoice: (value: string) => void;
}

export const PaymentInformationICTS: FC<Props> = ({
  form,
  currency,
  setCurrency,
  usingICTSModalForTaxes,
  setValueInvoice
}) => {
  const selectedPDF = Form.useWatch("invoicePDF", form);
  const paymentOption = Form.useWatch("paymentOption", form);
  const totalAmount = Form.useWatch("remainingAmount", form);
  const [loadingOCRInfos, setLoadingOCRInfos] = useState<boolean>(true);
  const currencyInput = useRef<InputRef>(null);
  const hideInputs = !selectedPDF || !selectedPDF[0]?.name


  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    (async () => {
      try {
        if (selectedPDF && selectedPDF[0]?.name) {
          setLoadingOCRInfos(true)
          const selectedModel = form?.getFieldValue('invoiceModel')

          const getTranslatedModelType = () => {
            if (selectedModel === 'NFS-E'
              || selectedModel === 'Nota Fiscal de Servi') {
              return 'NFS'
            }

            if (selectedModel === 'DANFE') {
              return 'DANFE'
            }

            return ''
          }

          const validModelTypeForOCR = getTranslatedModelType()
          if (validModelTypeForOCR) {
            const ocrResult = await api.postInvoiceOCR({
              fileType: validModelTypeForOCR,
              fileName: selectedPDF[0]?.name,
              fileContents: selectedPDF[0]?.originFileObj,
            })

            if (ocrResult.status === "Success") {
              const fileOCR = ocrResult.files[0]
              if(fileOCR.status === "200 - Success") {
                if (fileOCR?.emission_date !== ""){
                  const currentDate = new Date();
                  const currentMonth = currentDate.getMonth() + 1;
                  const currentYear = currentDate.getFullYear();
                  const emissionDate = dayjs(fileOCR.emission_date, "YYYY-MM-DD")
                  if (emissionDate.month() + 1 === currentMonth && emissionDate.year() === currentYear){
                    form.setFieldValue("issueDate", emissionDate);
                  }
                }
                if (fileOCR.nf_value !== ""){
                  form.setFieldValue("value", fileOCR.nf_value);
                }
                if (fileOCR.nf_number !== ""){
                  form.setFieldValue("invoiceNumber", fileOCR.nf_number);
                }
              }
            }
          }

        }
      } catch (err) {
        console.log(err)
      } finally {
        setLoadingOCRInfos(false)
      }
    })()
  }, [selectedPDF]);

  const [currencyMasked, setCurrencyMasked] = useState('')

  const maskCurrency = (value) => {
    value = value.replace("R$", "")
    value = value.replace(/\D/g, "")
    value = value.replace(/(\d)(\d{2})$/, "$1.$2")
    if (value > totalAmount) {
      form.setFieldValue('insufficientFunds', true)
      toast.error("O valor não pode ser maior que o valor restante")
    } else {
      form.setFieldValue('insufficientFunds', false)

    }
    const currencySymbols: {[key: string]: string} = {
      'EUR': '€',
      'USD': 'US$',
      'BRL': 'R$'
    };
    if (value.length < 3) {
      return `${currencySymbols[currency]} ${value}`;
    }
    value = (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: currency }).format(parseFloat(value)))
    return value;
  }

  const handleChange = (text: string) => {
    if (!text) return;
    const value = maskCurrency(text)
    setCurrencyMasked(value)   
    form.setFieldValue('value', value)
    setValueInvoice(value)
    return value
  }

  if (loadingOCRInfos) {
    return (
      <Col span={24}>
        <Row>
          <ContentLoader
            viewBox="0 0 845 305"
            foregroundColor="#f5f6f7"
            backgroundColor="#b1b1b1"
          >
            <rect x="0" y="0" rx="8" ry="8" width="120" height="15" />
            <rect x="0" y="25" rx="8" ry="8" width="845" height="30" />
            <rect x="0" y="75" rx="8" ry="8" width="120" height="15" />
            <rect x="0" y="105" rx="8" ry="8" width="845" height="120" />
            <rect x="0" y="245" rx="8" ry="8" width="120" height="15" />
            <rect x="0" y="275" rx="8" ry="8" width="415" height="30" />
            <rect x="425" y="245" rx="8" ry="8" width="120" height="15" />
            <rect x="425" y="275" rx="8" ry="8" width="415" height="30" />
          </ContentLoader>
        </Row>
      </Col>
    )
  }

  return (
    <div style={{
      rowGap: 24,
      flexFlow: 'row wrap',
      display: hideInputs ? 'none': 'flex',
    }}>
      <Form.Item
        name="issueDate"
        label="Data de emissão"
        colProps={{ span: 12 }}
        rules={[{ required: true }]}
        extra={!usingICTSModalForTaxes ? "Não é possível inserir NFs do mês anterior" : ""}
      >
        <DatePicker
          style={{ width: "100%" }}
          disabledDate={(d) => disableDaysBeforeToday(d,usingICTSModalForTaxes)}
        />
      </Form.Item>

      <Form.Item
        name="dueDate"
        colProps={{ span: 12 }}
        label="Data de vencimento"
        rules={[{ required: true }]}
        extra={!usingICTSModalForTaxes ? "O próximo vencimento disponível é em 15 dias" : ""}
      >
        <DatePicker
          style={{ width: "100%" }}
          disabledDate={(d) => disableDays15Days(d,usingICTSModalForTaxes)}
        />
      </Form.Item>
      <Form.Item
        name="paymentOption"
        colProps={{ span: 24 }}
        initialValue={'barCode'}
        label="Opções de pagamento"
        rules={[{ required: true }]}
      >
        <Radio
          size={"small"}
          options={[
            {
              label: "Boleto",
              value: "barCode",
            },
            {
              label: "Transferência",
              value: "transfer",
            },
          ]}
        />
      </Form.Item>
      {paymentOption === "barCode" && (
        <Form.Item
          name="barCode"
          colProps={{ span: 24 }}
          rules={[{ required: true }]}
          label="Código de barras - Boleto"
        >
          <Input
            type="text"
            placeholder=""
            controls={false}
            style={{ width: "100%" }}
          />
        </Form.Item>
      )}
      {paymentOption === "transfer" && (
        <>
          <Form.Item
            name="bank"
            label="Banco"
            colProps={{ span: 12 }}
            rules={[{ required: paymentOption === "transfer" }]}
          >
            <Input type="text" placeholder="" />
          </Form.Item>

          <Form.Item
            name="branch"
            label="Agência"
            colProps={{ span: 6 }}
            rules={[{ required: paymentOption === "transfer" }]}
          >
            <Input type="text" placeholder="" />
          </Form.Item>

          <Form.Item
            name="account"
            label="Conta"
            colProps={{ span: 6 }}
            rules={[{ required: paymentOption === "transfer" }]}
          >
            <Input type="text" placeholder="" />
          </Form.Item>
        </>
      )}
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'space-between',
        width: '100%',
      }}>
        <Form.Item
          name="invoiceNumber"
          label="Número da NF"
          colProps={{ span: 12 }}
          rules={[{ required: true }]}
        >
          <InputNumber
            size="small"
            type="number"
            placeholder=""
            controls={false}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item name="installment" label="Parcela n°:" colProps={{ span: 6 }}>
          <InputNumber
            size="small"
            type="number"
            placeholder=""
            controls={false}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="Valor"
          shouldUpdate
          colProps={{ span: 6 }}
        >
          <div style={{
            display: 'flex',
            flexDirection: 'row'
          }}>
            <Select
              ref={currencyInput}
              value={currency}
              options={selectCurrencyOptions}
              onChange={(e) => {
                setCurrency(e)
                form.setFieldValue('value', formatNumberToReal(0, e))
              }}
            />
            <Input
              type="text"
              value={currencyMasked}
              style={{ width: "100%" }}
              onChange={(e) => handleChange(e.target.value)}
            />
          </div>
        </Form.Item>
      </div>
      <Form.Item
        name="observations"
        label="Observações"
        colProps={{ span: 12 }}
        rules={[{ required: true }]}
      >
        <TextArea />
      </Form.Item>
      <Form.Item
        name="extraPDFs"
        label="Anexos extras"
        colProps={{ span: 12 }}
        getValueFromEvent={getFile}
      >
        <Upload accept={".pdf"} maxFilesCount={3} />
      </Form.Item>
      <Form.Item hidden name="insufficientFunds">  </Form.Item>
    </div>
  );
};
