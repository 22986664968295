import styled from 'styled-components'

export const Attachments = styled.div`
    display: flex;
    margin-top: 5px;
    flex-direction: column;

    button {
        padding: 0;
        border: none;
        outline: none;
        color: #004ca9;
        cursor: pointer;
        background: none;

        img {
            margin-right: 5px;
        }
    }
`