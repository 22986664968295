import { useState } from "react";
import { Body, Form, Modal, Upload, DatePicker, InputNumber } from "@icts/web";
import {
  formatNumberToReal,
  formatRealToNumber,
} from "../../../../../utils/format";
import { api } from "../../../../../Services/api";
import { toast } from "react-toastify";

export const ModalRegisterInvoiceBAND = ({
  invoice,
  setSelectedInvoice,
  handleCallInvoicesAPI,
  setOpenRegisterInvoiceModal,
}) => {
  const [form] = Form.useForm();
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleCloseModal = () => {
    form.resetFields();
    setSelectedInvoice(null);
    setOpenRegisterInvoiceModal(false)
  };

  const handleSubmitForm = async (data) => {
    try {
      setLoadingSubmit(true)
      const attachment = data.invoicePDF[0];

      var ext, valido;
      ext = attachment.name.substring(attachment.name.lastIndexOf(".")).toLowerCase();
      valido = ['.pdf'].includes(ext);
      
      if (!valido) {
          toast.error("Favor anexar a NF em formato PDF, outros formatos não são aceitos.")
          return
      }    

      const success = await api.postInvoiceAttach({
        id: invoice?.Id,
        invoiceNumber: data.invoiceNumber,
        fileContents: attachment.originFileObj,
        issueDate: new Date(data.issueDate).toISOString(),
        fileName: attachment.name?.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, ""),
      });

      if (success) {
        toast.success('Sucesso ao adicionar nota')

        handleCallInvoicesAPI()
        handleCloseModal();
      } else {
        toast.error('Erro ao adicionar nota')
      }
    } catch (err) {
      toast.error('Erro ao adicionar nota')  
    } finally {
      setLoadingSubmit(false)
    }
  };

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Modal
      open
      okText="Salvar"
      onOk={() => form.submit()}
      title="Cadastrar Nota Fiscal"
      confirmLoading={loadingSubmit}
      onCancel={() => handleCloseModal()}
    >
      <div style={{ marginTop: 16, marginBottom: 40 }}>
        <Body variant="paragraph">
          Ao anexar a Nota Fiscal, certifique-se que esteja em formato .PDF
        </Body>
      </div>

      <Form form={form} onFinish={handleSubmitForm}>
        <Form.Item
          name="invoicePDF"
          label="Nota Fiscal"
          getValueFromEvent={getFile}
          rules={[{ required: true }]}
        >
          <Upload accept={".pdf"} maxFilesCount={1} />
        </Form.Item>

        <Form.Item
          name="invoiceNumber"
          label="Número da Nota Fiscal"
          validateTrigger="onBlur"
          colProps={{ span: 12 }}
          rules={[{ required: true }]}
        >
          <InputNumber
            size="small"
            type="number"
            controls={false}
            style={{ width: "100%" }}
            placeholder="Informe o número da sua nota"
          />
        </Form.Item>
        <Form.Item
          name="issueDate"
          label="Data de emissão NF"
          colProps={{ span: 12 }}
          rules={[{ required: true }]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="invoiceValue"
          label="Valor da Nota Fiscal"
          validateTrigger="onBlur"
          colProps={{ span: 12 }}
        >
          <InputNumber
            disabled
            size="small"
            controls={false}
            style={{ width: "100%" }}
            parser={formatRealToNumber}
            formatter={formatNumberToReal}
            placeholder="Informe o valor da nota fiscal"
            defaultValue={Number(invoice?.Valor_x0020_da_x0020_Nota)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
