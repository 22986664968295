export const invoicesCategories = {
    '0': "Nenhum",
    '1': "Valor Contratual",
    '2': "G.M.Comissão",
    '3': "G.M.Merchandising",
    '4': "Representação",
    '5': "Prêmio",
    '6': "Prêmio 2",
    '7': "Comissão",
    '8': "Merchandising",
    '9': "Complemento Salarial",
    '10': "Participação no Resultado",
    '11': "Adicional Extra",
    '12': "Adiantamento",
    '13': "Dif.Reajuste Contratual",
    '14': "Férias",
    '15': "Antecipação de 13o.Salário",
    '16': "13o.Salário 2a. parcela",
    '17': "Verba SA",
    '18': "Patrocínio",
    '19': "Direito Autoral",
    '20': "Rescisão Contratual",
    '21': "Participação no Resultado II",
    '22': "Merchandising 2",
    '23': "Prestação de contas",
    '24': "Interatividade",
    '25': "Diferença de Férias",
    '26': "Conferencia",
    '27': "Arquivos Movimentos",
    '28': "Merchandising Avulso Apropriado",
    '29': "Comissão II",
    '30': "Comissão III",
    '31': "13o.Salário 2a. parcela - SA",
    '32': "Adicional Fixo",
    '33': "Merchandising 3",
    '34': "Ad. GM. Merchandising",
    '35': "Adicional Extra II",
    '36': "Merchandising 4",
    '37': "Excluídos pela Band",
    '38': "Merchandising 5",
    '39': "Merchandising 6",
    '40': "Segunda Função PJ",
    '41': "Diferença 13º Salário - PJ",
    '42': "PPR",
    '43': "Reemb. Assist. Med PJ",
    '44': "Merchandising Prov. PJ",
    '45': "PPR II PJ",
    '46': "Merchandising Prov.2 PJ",
    '47': "Merchandising Prov.3 PJ",
    '48': "Merchandising Prov.4 PJ",
    '49': "Merchandising Prov.5 PJ",
    '50': "Merchandising Prov. 6 PJ",
    '51': "Dif. GM Comissão PJ",
    '52': "Complem. Rem. Contratual II",
    '53': "GM de Licenciamento",
    '54': "Dif. Comissão",
    '55': "Complem. Rem. Contratual",
    '56': "Dif. Direito Autoral e Conexos",
    '57': "Prest. Conta Direito Autoral",
    '58': "Direito Autoral SA",
    '59': "Adicional Extra 3 - PJ",
    '60': "Direito Autoral Variavel",
    '61': "Direito Autoral Variavel II",
    '62': "13o.Salário Antec. PJ - SA",
    '63': "Interatividade 1",
    '64': "Prêmio 3",
    '65': "Comissão Fixa",
    '66': "Comissão IV",
    '67': "Participação no Resultado Comercial PJ",
    '68': "Complem. Rem. Contratual III",
    '69': "Participação no Resultado Comercial II PJ",
    '70': "Direito Autoral Variavel III",
    '71': "Remuneração Variavel",
    '72': "Abono Folha PJ",
    '73': "Direito Autoral Variavel IV",
    '74': "Direito Autoral Variavel V",
    '75': "Direito Autoral Variavel VI",
    '76': "Cota de Patrocinio",
    '77': "Cota de Patrocinio II",
    '78': "Cota de Patrocinio III",
    '79': "Complemento Salarial PJ II",
    '80': "Prêmio IV",
    '81': "Participação no Resultado III",
    '82': "Comissão V",
    '83': "Comissão VI",
    '84': "Comissão VII",
    '85': "Adicional Extra PJ IV",
    '86': "Adicional Extra PJ V",
    '87': "Adic Fixo 13o.Salário 2a. parcela",
    '88': "Adicional Extra III",
    '89': "Compl 13º PJ",
};
