import {
  Row,
  Col,
  Form,
  Upload,
  Select,
  FormInstance,
} from "@icts/web";
import { IModel } from "@/types/invoiceICTS";
import { FC, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { api } from "../../../../../../../Services/api";
import { getSelectOptionsFromArr } from "@/utils/getSelectOptionsFromArr";

interface Props {
  form?: FormInstance<any>;
  setAbsEntry: (newState: string) => void;
}

export const InvoiceInformationICTS: FC<Props> = ({ form, setAbsEntry }) => {
  const [models, setModels] = useState<IModel[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const selectedModel = Form.useWatch("invoiceModel", form);

  const handleGetInfosFromFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  useEffect(() => {
    (async () => {
      const apiModels = await api.getModels()
      if (!apiModels) {
        return;
      }

      setModels(apiModels);
      setLoading(false);
    })()
  }, []);

  const getSelectedModelAbsEntry = (selectedModelName) => {
    const selectedModelObj = models.find(model => model.NFMName === selectedModelName)

    return selectedModelObj?.AbsEntry || ''
  }

  return (
    <>
      {loading ? (
        <Col span={24}>
          <Row>
            <ContentLoader
              viewBox="0 0 845 55"
              foregroundColor="#f5f6f7"
              backgroundColor="#b1b1b1"
            >
              <rect x="0" y="0" rx="8" ry="8" width="120" height="15" />
              <rect x="0" y="25" rx="8" ry="8" width="845" height="30" />
            </ContentLoader>
          </Row>
        </Col>
      ) : (
        <>
          <Form.Item
            name="invoiceModel"
            label="Modelo de Notas"
            colProps={{ span: 12 }}
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Modelo de Notas"
              options={getSelectOptionsFromArr("NFMName", models)}
              onChange={(selectedModel) => {
                setAbsEntry(getSelectedModelAbsEntry(selectedModel))
              }}
            />
          </Form.Item>

          <Form.Item
            name="invoicePDF"
            label="Nota fiscal"
            colProps={{ span: 24 }}
            rules={[{ required: true }]}
            getValueFromEvent={handleGetInfosFromFile}
            style={{ display: selectedModel ? 'block' : 'none '}}
          >
            <Upload accept={".pdf"} maxFilesCount={1} />
          </Form.Item>
        </>
      )}
    </>
  );
};
