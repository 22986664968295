import { IData, IInvoicePDF } from "@/types/invoiceICTS";
import { Form, Col, Typography, Row, Result, FormInstance } from "@icts/web";
import { useAtom } from "jotai";
import { Loading } from "@icts/icons";
import { FC, useEffect, useState } from "react";
import { api } from "../../../../../../Services/api";
import { companyAtom } from "../../../../../../store";
import { IPos } from "@/types/invoiceICTS";
import { toast } from "react-toastify";
import { formatNumberToReal } from "@/utils/format";
import { POInformationICTS } from "./POInformationICTS";
import { InvoiceInformationICTS } from "./InvoiceInformationICTS";
import { PaymentInformationICTS } from "./PaymentInformationICTS";
import { getCurrencyOnPowerAppsFormat } from "@/utils/currency/getCurrencyOnPowerAppsFormat";
const { Text } = Typography;

interface Props {
  form: FormInstance<any>;
  handleCloseModal: () => void;
  usingICTSModalForTaxes: boolean;
  handleCallInvoicesAPI: () => void;
  setLoadingSubmit: (newState: boolean) => void;
}

export const RegisterInvoiceICTSForm: FC<Props> = ({
  form,
  setLoadingSubmit,
  handleCloseModal,
  handleCallInvoicesAPI,
  usingICTSModalForTaxes,
}) => {
  const [company] = useAtom(companyAtom);
  const [pos, setPos] = useState<IPos[]>([]);
  const selectedPO = Form.useWatch("poCode", form);
  const [absEntry, setAbsEntry] = useState<string>('');
  const totalAmount = Form.useWatch("totalAmount", form);
  const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL
  const [currency, setCurrency] = useState<string>('BRL');
  const [valueInvoice, setValueInvoice] = useState('')
  const [loadingPos, setLoadingPos] = useState<boolean>(true);
  const [emptyRemainingAmount, setEmptyRemainingAmount] = useState<boolean>(false);

  const handleSubmitForm = async (data: IData) => {
    try {
      setLoadingSubmit(true)

      var ext, valido;
      ext = data?.invoicePDF[0].name.substring(data?.invoicePDF[0].name.lastIndexOf(".")).toLowerCase();
      valido = ['.pdf'].includes(ext);
      
      if (!valido) {
        toast.error("Favor anexar a NF em formato PDF, outros formatos não são aceitos.")
        return
      }  

      const attachments = data?.extraPDFs !== undefined ? [...data?.invoicePDF, ...data?.extraPDFs] : data?.invoicePDF

      const attachment = attachments?.map((invoicePDF: IInvoicePDF) => {
        return {
          fileContents: invoicePDF.originFileObj,
          name: invoicePDF.name?.replace(/[&\/\\#,+()$~%'":*?<>{}]/g, ""),
        };
      });

      const currencySymbols = {
        'EUR': '€',
        'USD': 'US$',
        'BRL': 'R$'
      };

      const selectedPOObject = pos.find(({ codigo_x0020_PO }) => codigo_x0020_PO === data.poCode)
     
      if(!valueInvoice.replace(currencySymbols[currency], '').trim() || +valueInvoice.replace(currencySymbols[currency], '').replace(',','.').trim() == 0){
        toast.error("Por favor, Insira o Valor")
        return
      }

      const success = await api.postNewInvoice({
        cnpj: company?.cnpj,
        absEntry: absEntry,
        razaoSocial: company?.razao_social,
        filesArray: attachment,
        invoiceNumber: data.invoiceNumber,
        issueDate: new Date(data.issueDate),
        dueDate: new Date(data.dueDate),
        barCode: data.barCode ?? null,
        installment: data.installment ?? null,
        bank: data.bank ?? null,
        branch: data.branch ?? null,
        account: data.account ?? null,
        observations: data.observations,
        poCode: data.poCode,
        invoiceModel: data.invoiceModel,
        isTaxType: usingICTSModalForTaxes,
        poId: selectedPOObject.Id,
        isTransfer: data.paymentOption === 'transfer',
        currency: getCurrencyOnPowerAppsFormat(currency),
        value: valueInvoice.replace(currencySymbols[currency], '').trim()
      });

      if (success) {
        toast.success('Sucesso ao cadastrar nota')

        handleCallInvoicesAPI()
        handleCloseModal();
      } else {
        toast.error('Erro ao cadastrar nota')
      }
    } catch (err) {
      toast.error('Erro ao cadastrar nota')
    } finally {
      setLoadingSubmit(false)
    }
  };

  useEffect(() => {
    if (company) {
      (async () => {
        const apiPos = await api.getPOs("?cnpj=" + company.cnpj);
        if (!apiPos) {
          return;
        }

        const filteredPOs = apiPos?.filter(PO => PO?.codigo_x0020_PO !== 0 && PO?.codigo_x0020_PO !== '0')

        setPos(filteredPOs);
        setLoadingPos(false);
      })()
    }
  }, []);

  if (loadingPos) {
    return (
      <Col span={24}>
        <Row align="middle" justify="center" style={{ padding: 40 }}>
          <Loading size={32} />
        </Row>
      </Col>
    )
  }

  if (pos?.length <= 0) {
    return (
      <Result
        status="warning"
        title={`Você não possui pedidos de compra para o CNPJ ${company.cnpj}`}
        subTitle={`Entre em contato com o time de suporte e solicite a configuração da sua conta pelo e-mail ${supportEmail}.`}
      />
    )
  }

  return (
    <Form form={form} onFinish={handleSubmitForm}>
      <POInformationICTS
        pos={pos}
        form={form}
        setEmptyRemainingAmount={setEmptyRemainingAmount}
      />

      {selectedPO && totalAmount !== undefined && (
        <>
          {emptyRemainingAmount ? (
            <Col>
              <Text>Esta P.O. já está finalizada</Text>
            </Col>
          ) : (
            <>
              <InvoiceInformationICTS
                form={form}
                setAbsEntry={setAbsEntry}
                usingICTSModalForTaxes={usingICTSModalForTaxes}
              />

              {/* testar submits do modal */}
              <PaymentInformationICTS 
                form={form} 
                setValueInvoice={setValueInvoice}
                currency={currency}
                setCurrency={setCurrency}
                usingICTSModalForTaxes={usingICTSModalForTaxes}
              /> 
            </>
          )}
        </>
      )}
    </Form>
  );
};
