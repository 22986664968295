import { FC, useState } from 'react'
import { RegisterInvoiceICTSForm } from "./Form";
import { Form, Modal } from "@icts/web";

interface Props {
  usingICTSModalForTaxes: boolean
  setOpenRegisterInvoiceModal: any;
  handleCallInvoicesAPI: () => void;
  setUsingICTSModalForTaxes: (newState: boolean) => void;
}

export const ModalRegisterInvoiceICTS: FC<Props> = ({
  handleCallInvoicesAPI,
  usingICTSModalForTaxes,
  setUsingICTSModalForTaxes,
  setOpenRegisterInvoiceModal,
}) => {
  const [form] = Form.useForm();
  const selectedPO = Form.useWatch("poCode", form);
  const insufficientFunds = Form.useWatch("insufficientFunds", form);

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const handleCloseModal = () => {
    form.resetFields();
    setUsingICTSModalForTaxes(false)
    setOpenRegisterInvoiceModal(false);
  };

  return (
    <Modal
      open
      width={"70%"}
      okText="Salvar"
      onOk={() => form.submit()}
      onCancel={handleCloseModal}
      confirmLoading={loadingSubmit}
      title={usingICTSModalForTaxes ? "Cadastrar Imposto" : "Cadastrar Nota Fiscal"}
      okButtonProps={{ style: { display: selectedPO && !insufficientFunds ? 'inline-block' : 'none' } }}
      cancelButtonProps={{ style: { display: selectedPO ? 'inline-block' : 'none' } }}
    >
      <RegisterInvoiceICTSForm 
        form={form}
        handleCloseModal={handleCloseModal}
        setLoadingSubmit={setLoadingSubmit}
        handleCallInvoicesAPI={handleCallInvoicesAPI}
        usingICTSModalForTaxes={usingICTSModalForTaxes}
      />
    </Modal>
  );
};
