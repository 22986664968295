import dayjs from "dayjs";
import { getStatusInfos } from "./getInvoiceStatusInfos";

export const getParseInvoiceInfos = (invoice) => {
  const id = invoice.Id;
  const category = invoice.Categoria
  const poCode = invoice.codigo_x0020_PO
  const amount = (invoice.Valor_x0020_da_x0020_Nota || invoice.Valor_x0020_Final).length > 3 ? (invoice.Valor_x0020_da_x0020_Nota || invoice.Valor_x0020_Final) : (invoice.Valor_x0020_da_x0020_Nota || invoice.Valor_x0020_Final)+ ',00';
  const number =
    invoice.Numero_x0020_da_x0020_Nota || invoice.Nota_x0020_FIscal;
  const statusInfo = getStatusInfos(invoice.Status || invoice.STATUS);
  const dateIssue = (invoice.DatadeEmissao || invoice.Data_x0020_da_x0020_Emissao) ? dayjs(
    invoice.DatadeEmissao || invoice.Data_x0020_da_x0020_Emissao
  ).format("DD/MM/YYYY") : null;
  const dueDate = (invoice.datadeVENCIMENTO || invoice.Data_x0020_de_x0020_Vencimento) ? dayjs(
    invoice.datadeVENCIMENTO || invoice.Data_x0020_de_x0020_Vencimento
  ).format("DD/MM/YYYY") : null;
  const policyHolder = {
    cnpj: invoice.Cnpj_TomadorServico || invoice.Cnpj,
    razaoSocial: invoice.RazaoSocial_TomadorServico || invoice.Razao_x0020_Social_x0020_Fornece,
  };
  const currency = invoice?.Moeda || 'R$'

  return {
    id,
    poCode,
    amount,
    number,
    dueDate,
    currency,
    category,
    dateIssue,
    statusInfo,
    policyHolder,
  };
};
