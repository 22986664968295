import axios from "axios";
import { kcAuthClient } from "./keycloak";

const axiosInstance = () => {
  const config = {
    baseURL: import.meta.env.VITE_API_BASE_URL,
  };

  if (kcAuthClient.authenticated && kcAuthClient.token) {
    config.headers = config.headers || {};
    config.headers = {
      Authorization: `Bearer ${kcAuthClient.token}`,
      ...config.headers,
    };
  }

  return axios.create(config);
};

export const api = {
  getUserInfo: async () => {
    try {
      const response = await axiosInstance().get("/users/me");
      return response.data.id ? response.data : null;
    } catch (ex) {
      return null;
    }
  },

  getUserCNPJs: async () => {
    try {
      const response = await axiosInstance().get(
        "/sharepoint/list-cnpj-razao-social"
      );
      return response?.data?.length ? response.data : null;
    } catch (ex) {
      return null;
    }
  },

  getInvoices: async (cnpj) => {
    try {
      const response = await axiosInstance().get(
        "/sharepoint/list-nf?cnpj=" + cnpj
      );
      return response?.data?.length ? response.data : null;
    } catch (ex) {
      return null;
    }
  },

  getInvoiceAttachments: async (invoiceId) => {
    try {
      const response = await axiosInstance().get(
        "/sharepoint/attachment-url?id=" + invoiceId
      );

      return response?.data?.fileNames?.length ? response.data.fileNames : null;
    } catch (ex) {
      return null;
    }
  },

  getInvoiceDownloadAttachment: async (invoiceId, fileName) => {
    try {
      const response = await axiosInstance().get(
        `/sharepoint/download-attachment?id=${invoiceId}&fileName=${fileName}`
      );

      return response?.data?.length ? response.data : null;
    } catch (ex) {
      return null;
    }
  },

  postInvoiceAttach: async (data) => {
    try {
      await axiosInstance().post(
        "/sharepoint/update",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return true
    } catch (ex) {
      return null;
    }
  },
  getPOs: async (cnpj) => {
    try {
      const response = await axiosInstance().get(
        "/sharepoint/product-orders" + cnpj
      );
      return response?.data?.length ? response.data : null;
    } catch (ex) {
      return null;
    }
  },

  getModels: async () => {
    try {
      const response = await axiosInstance().get(
        "/sharepoint/model"
      );
      return response?.data?.length ? response.data : null;
    } catch (ex) {
      return null;
    }
  },

  getAmounts: async (po) => {
    try {
      const response = await axiosInstance().get(
        "/sharepoint/amounts" + po
      );
      return response?.data ?? null;
    } catch (ex) {
      return null;
    }
  },

  postNewInvoice: async (data) => {
    try {
      const response = await axiosInstance().post(
        "/sharepoint/create",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data.Id ? true : false;
    } catch (ex) {
      return null;
    }
  },

  postInvoiceOCR: async (data) => {
    try {
      const response = await axiosInstance().post(
        "/sharepoint/nf-ocr",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (ex) {
      return null;
    }
  },
};
