import { useEffect, useState } from "react";
import { Loading } from "@icts/icons";
import { Modal, Space, Typography, Row } from "@icts/web";
const { Text } = Typography;
import * as S from "./styles";
import { api } from "../../../../../Services/api";
import AttachmentSVG from "../../../../../assets/attachment.svg";

export const ModalShowInvoice = ({ setOpen, invoiceId }) => {
  const [loading, setLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL

  useEffect(() => {
    (async () => {
      if (invoiceId) {
        setLoading(true);
        const resAttachments = await api.getInvoiceAttachments(invoiceId);

        setAttachments(resAttachments);
        setLoading(false);
      }
    })();
  }, [invoiceId]);

  const handleOpenFile = async (name) => {
    setLoading(true);
    const attachmentLink = await api.getInvoiceDownloadAttachment(invoiceId, name)
    window.open(attachmentLink, '_blank').focus();
    setLoading(false);
  }

  return (
    <Modal
      open
      title="Nota"
      okText="Fechar"
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      {loading ? (
        <Row align="middle" justify="center">
          <Loading size={32} />
        </Row>
      ) : (
        <>
          {attachments?.length ? (
            <Space direction="vertical" justify="space-between">
              <Text>Abrir anexo enviado?</Text>
              <S.Attachments>
                {attachments.map((fileName) => (
                  <button type="button" onClick={() => handleOpenFile(fileName)}>
                    <img src={AttachmentSVG} alt="icone de arquivo" />
                    {fileName}
                  </button>
                ))}
              </S.Attachments>
            </Space>
          ) : (
            <Text>
              Arquivo indisponível para download. Entre em contato com  
              <a href={`mailto:${supportEmail}`}> {supportEmail} </a>
              caso precise consultar o arquivo enviado
            </Text>
          )}
        </>
      )}
    </Modal>
  );
};
