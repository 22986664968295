import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react'
import { useKeycloak } from '@react-keycloak/web'
import PropTypes from 'prop-types'
import { GlobalContext } from './global'
import { api } from '../Services/api'

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const { keycloak, initialized } = useKeycloak()
  const [user, setUser] = useState(null)
  const { showSpinner, hideSpinner } = useContext(GlobalContext)

  const loadUser = useCallback(async () => {
    showSpinner()
    const userInfo = await api.getUserInfo()
    hideSpinner()

    if (!userInfo) {
      return false
    }

    setUser(userInfo)
    return true
  }, [showSpinner, hideSpinner])

  useEffect(() => {
    if (!user && initialized && keycloak.authenticated) {
      loadUser().then((loaded) => {
        if (!loaded) {
          keycloak.logout()
        }
      })
    }
  }, [user, initialized, keycloak.authenticated])

  if (!initialized) {
    return null
  }

  if (!keycloak.authenticated) {
    keycloak.login()
    return null
  }

  if (!user) {
    return null
  }

  return (
    <AuthContext.Provider
      value={{ user, setUser, logout: () => keycloak.logout() }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}
