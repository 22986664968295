
import styled, { css } from "styled-components";
import { Card, Button, Typography } from "@icts/web";
import { color } from "@icts/tokens";
const { Text } = Typography;

export const StatusCard = styled(Card)`
  border-radius: 8px;

  .ant-card-head {
    min-height: 0;
    padding-top: 16px;
    border-bottom: 0px;
  }

  .ant-card-body {
    ${({ isBandEnv }) => isBandEnv && css`
      padding-top: 0;
    `};
  }

  &::before {
    top: -1px;
    left: -1px;
    content: '';
    width: 11px;
    height: 100%;
    background: red;
    position: absolute;
    border-radius: 8px 0 0 8px;
    background: ${({ borderColor }) => borderColor};
  }
`;

export const CardActionButton = styled(Button)`
    color: ${color.neutral.light.pure};
    background: ${color.system.blue.dark};

    &:not(:disabled):hover {
        background: ${color.system.blue.pure};
    }
`

export const InvoiceValue = styled(Text)`

  button {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    background: none;

    img {
      margin-left: 8px;
    }
  }
`