import './Icon.scss';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { prefixFactory } from 'icts-helpers';

const prefix = prefixFactory('icon--');

const sizes = prefix([
  'xs',
  'sm',
  'md',
  'lg',
]);

const Icon = ({
  name, size, className, rotate, style, title, onClick,
}) => {
  const styles = { ...style, ...(rotate ? { transform: `rotate(${rotate})` } : {}) };

  return (
    <i
      onClick={onClick}
      style={styles}
      className={
        classNames('icon',
          sizes[size],
          `icon-${name}`,
          className)
      }
      title={title}
    />
  );
};

Icon.propTypes = {
  rotate: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.any,
  size: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  size: 'xs',
  style: null,
  rotate: null,
  className: null,
  title: '',
  onClick: () => {},
};

export default Icon;
