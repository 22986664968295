import dayjs from "dayjs";

export const selectCurrencyOptions = [
  { value: 'BRL', label: 'BRL' }, 
  { value: 'USD', label: 'USD' }, 
  { value: 'EUR', label: 'EUR' }
]

const today = new Date();
  const todayString = today.toISOString();
  const todayStringSplited = todayString.split("T");
  const ymd = todayStringSplited[0].split("-");
  ymd[2] = "01"; //dia minimo
  const minDate = new Date(ymd.join("-") + "T03:00:01.908Z"); // time fica 00:00:01 de brasilia


export const disableDaysBeforeToday = (d: any, usingICTSModalForTaxes: boolean) => {
      if (usingICTSModalForTaxes) {
        return false
      }

      if (d == null) {
        return false;
      }

      return (
        minDate != null && d < minDate
        // &&
        // !d.isSame(minDate, 'day')
      );
    }

  export const disableDays15Days = 
    (d: any, usingICTSModalForTaxes: boolean) => {
      if (usingICTSModalForTaxes) {
        return false
      }

      if (d == null) {
        return false;
      }

      const todayDate = dayjs().add(15, 'day')

      return d < todayDate;
    }