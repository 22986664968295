import { FC, useState } from "react";
import * as S from "./styles";
import { Space, Tag, Row, Col, Typography } from "@icts/web";
import { invoicesCategories } from "../../utils/invoicesCategories";
import { color } from "@icts/tokens";
const { Text } = Typography;
import EyeSVG from "../../assets/eye.svg";
import EyeSlashSVG from "../../assets/eye-slash.svg";
import { getParseInvoiceInfos } from "../../utils/getParseInvoiceInfos";
import { getIfTodayIsAValidDayToUploadTaxes } from "@/utils/getIfTodayIsAValidDayToUploadTaxes";

interface Props {
  invoice: any;
  setSelectedInvoice: () => void;
  setOpenSeeInvoiceModal: () => void;
  setOpenRegisterInvoiceModal: () => void;
}

export const InvoiceCard: FC<Props> = ({
  invoice,
  setSelectedInvoice,
  setOpenSeeInvoiceModal,
  setOpenRegisterInvoiceModal,
}) => {
  const [showValue, setShowValue] = useState(false);
  const isBandEnv = import.meta.env.VITE_COMPANY_ENV === "BAND";
  const todayIsAValidDayToUploadTaxes = getIfTodayIsAValidDayToUploadTaxes()

  const {
    poCode,
    amount,
    number,
    dueDate,
    category,
    currency,
    dateIssue,
    statusInfo,
    policyHolder,
  } = getParseInvoiceInfos(invoice);

  return (
    <Col span={8}>
      <S.StatusCard
        isBandEnv={isBandEnv}
        borderColor={statusInfo?.color}
        title={!isBandEnv ? "Informações" : "Tomador de serviço"}
        extra={
          <Tag color={statusInfo?.tagColor} style={{ margin: 0 }}>
            {statusInfo?.name}
          </Tag>
        }
      >
        {isBandEnv && (
          <>
            <Row>
              <Col>
                <Text>{policyHolder.cnpj}</Text>
              </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
              <Col>
                <Text>{policyHolder.razaoSocial}</Text>
              </Col>
            </Row>
          </>
        )}

        <Row justify="space-between" style={{ marginBottom: 16 }}>
          <Col>
            <Row justify="space-between"  style={{ marginBottom: 16 }}>
              <Space size={0} direction="vertical">
                {isBandEnv ? (
                  <>
                    <Text strong>Categoria</Text>
                    <Text>{invoicesCategories[category] || '---'}</Text>
                  </>
                ) : (
                  <>
                    <Text strong>N° da P.O</Text>
                    <Text>{poCode || '---'}</Text>
                  </>
                )}
              </Space>
            </Row>

            <Row justify="space-between">
              <Space size={0} direction="vertical">
                <Text strong>Nota Fiscal</Text>
                <Text>{number || '---'}</Text>
              </Space>
            </Row>
          </Col>
          <Col>
            <Row justify="space-between" style={{ marginBottom: 16 }}>
              <Space size={0} direction="vertical">
                <Text strong>Data de vencimento</Text>
                <Text>{dueDate || '---'}</Text>
              </Space>
            </Row>

            <Row justify="space-between">
              <Space size={0} direction="vertical">
                <Text strong>Data emissão NF</Text>
                <Text>{dateIssue || '---'}</Text>
              </Space>
            </Row>
          </Col>
        </Row>

        <Row justify="space-between" align="middle">
          <Col>
            <Space size={0} direction="vertical" justify="space-between">
              <S.InvoiceValue strong>
                Valor nota fiscal
                <button
                  type="button"
                  onClick={() => setShowValue((prevState) => !prevState)}
                >
                  {showValue ? (
                    <img src={EyeSlashSVG} alt="slash eye icon" />
                  ) : (
                    <img src={EyeSVG} alt="eye icon" />
                  )}
                </button>
              </S.InvoiceValue>
              <Text strong>
                {showValue ? (
                  <span style={{ color: color.feedback.informative.dark }}>
                    {amount ? `${currency} ${amount}` : '---'}
                  </span>
                ) : (
                  <span>
                    ....
                  </span>
                )}
              </Text>
            </Space>
          </Col>
          <Col>
            {isBandEnv && (
              <>
                {statusInfo?.name === "Aberto" ? (
                  <S.CardActionButton
                    size="small"
                    type="primary"
                    shape="round"
                    disabled={!todayIsAValidDayToUploadTaxes}
                    onClick={() => {
                      if (todayIsAValidDayToUploadTaxes) {
                        setSelectedInvoice(invoice);
                        setOpenRegisterInvoiceModal(true);
                      }
                    }}
                  >
                    + Adicionar NF
                  </S.CardActionButton>
                ) : (
                  <S.CardActionButton
                    type="primary"
                    shape="round"
                    size="small"
                    onClick={() => {
                      setSelectedInvoice(invoice);
                      setOpenSeeInvoiceModal(true);
                    }}
                  >
                    Ver nota
                  </S.CardActionButton>
                )}
              </>
            )}

            {!isBandEnv && (
              <S.CardActionButton
                type="primary"
                shape="round"
                size="small"
                onClick={() => {
                  setSelectedInvoice(invoice);
                  setOpenSeeInvoiceModal(true);
                }}
              >
                Ver anexo
              </S.CardActionButton>
            )}
          </Col>
        </Row>
      </S.StatusCard>
    </Col>
  );
};
