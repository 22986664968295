import { removeDuplicatesOnArray } from "./removeDuplicatesOnArray";

export const getSelectOptionsFromArr = (fieldName: string, items: any[]) => {
    const options = items.map((item) => item[fieldName])
    const optionsWithoutDuplicates = removeDuplicatesOnArray(options)

    return optionsWithoutDuplicates.map((option) => ({
        value: option,
        label: option,
    }));
};