import styled from 'styled-components'

export const InvoicesList = styled.div`
    margin: -72px 32px 0 32px; 
`

export const EmptyList = styled.div`
    height: 100%;
    display: flex;
    margin-top: 60px;
    align-items: center;
    flex-direction: column;

    img {
        height: 66px;
        margin-bottom: 30px;
    }

    h1 {
        font-size: 24px;
    }
`