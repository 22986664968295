export const getFilteredBandInvoices = (invoices) => {
    if (!invoices?.length) return invoices

    const filteredBandInvoices = invoices.reduce((allUniqueInvoices, invoice) => {
        const repeatedInvoice = allUniqueInvoices.some(
        (uniqueInvoice) =>
            uniqueInvoice.Matricula === invoice.Matricula &&
            uniqueInvoice.Categoria === invoice.Categoria &&
            uniqueInvoice.datadeVENCIMENTO === invoice.datadeVENCIMENTO &&
            uniqueInvoice.Valor_x0020_da_x0020_Nota ===
            invoice.Valor_x0020_da_x0020_Nota
        );

        if (!repeatedInvoice) {
            allUniqueInvoices.push(invoice);
        }

        return allUniqueInvoices;
    }, []);

    return filteredBandInvoices;
};
