import { useState, useEffect } from 'react';

/**
 * Order here matters!
 */
const breakpoints = {
  xlarge: Infinity,
  large: 1200,
  medium: 992,
  small: 768,
  xsmall: 525,
};

const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

export const useMapPropToBreakpoint = (props, defaultProp, map) => {
  const [
    value, setValue,
  ] = useState(props[defaultProp]);

  useEffect(() => {
    function resize () {
      const width = getWidth();
      const [
        prop,
      ] = Object
        .entries(map)
        .reduce(([propKey, last], [breakpoint, key]) => {
          const size = breakpoints[breakpoint];

          return size < last && width < size
            ? [key, size]
            : [propKey, last];
        }, [
          '', Infinity,
        ]);

      setValue(props[prop] || props[defaultProp]);
    }

    resize();

    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, [
    props, defaultProp, map,
  ]);

  return value;
};

export const prefixFactory = (prefix) => (entries) => entries.reduce((obj, entry) => {
  obj[entry] = prefix + entry;

  return obj;
}, {});

export const useSelect = (initial = []) => {
  const [array, setArray] = useState(initial);

  const toggle = (item) => {
    const index = array.findIndex((option) => option.value === item.value);

    if (index === -1) {
      setArray([
        ...array,
        item,
      ]);

      return;
    }

    array.splice(index, 1);

    setArray([
      ...array,
    ]);
  };

  return [
    array,
    toggle,
    setArray,
  ];
};
