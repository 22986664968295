import { useAtom } from "jotai";
import { FC, useMemo, useState } from "react";
import { api } from "../../../../../../../Services/api";
import { companyAtom } from "../../../../../../../store";
import {
  formatNumberToReal,
  formatRealToNumber,
} from "../../../../../../../utils/format";
import {
  Row,
  Col,
  Form,
  Select,
  InputNumber,
  FormInstance,
} from "@icts/web";
import ContentLoader from "react-content-loader";
import { IPO } from "@/types/PO";
import { removeDuplicatesOnArrayOfObj } from "@/utils/removeDuplicatesOnArray";

interface Props {
  pos: IPO[],
  form?: FormInstance<any>;
  setEmptyRemainingAmount: (newState: boolean) => void;
}

export const POInformationICTS: FC<Props> = ({
    pos,
    form,
    setEmptyRemainingAmount,
  }) => {
    const [company] = useAtom(companyAtom);
    const selectedPO = Form.useWatch("poCode", form);
    const [loadingAmounts, setLoadingAmounts] = useState<boolean>(true);

    const formattedPos = useMemo(
      () => {
        const posWithCustomLabel = [...pos, ...pos].map(po => {
          return ({
            value: po.codigo_x0020_PO,
            label: `PO ${po.codigo_x0020_PO} - Filial Icts ${po.CnpjFilial}`
          })
        })

        const optionWithoutDuplicates = removeDuplicatesOnArrayOfObj(posWithCustomLabel, 'value')
        return optionWithoutDuplicates
      },
      [pos]
    );

    const callApiGetAmount = async (PO) => {
      const selectedPOObject = pos.find(({ codigo_x0020_PO }) => codigo_x0020_PO === PO)

      if (selectedPOObject.Id && PO) {
        const apiAmounts = await api.getAmounts(`?productOrder=${PO}&poId=${selectedPOObject.Id}&cnpj=${company.cnpj}`);

        if (apiAmounts?.remaining_amount <= 0) {
          setEmptyRemainingAmount(true);
        } else {
          setEmptyRemainingAmount(false);
        }

        if (!apiAmounts) {
          return;
        }

        form.setFieldValue("totalAmount", apiAmounts.total_amount);
        form.setFieldValue("remainingAmount", apiAmounts.remaining_amount);

        setLoadingAmounts(false);
      }
    };

    return (
      <>
        <Form.Item
          name="poCode"
          label="Selecione o n° do seu pedido de compra"
          rules={[{ required: true }]}
          colProps={{ span: 24 }}
        >
          <Select
            allowClear
            showSearch
            options={formattedPos}
            optionFilterProp="label"
            onChange={(e) => {
              setLoadingAmounts(true);
              callApiGetAmount(e);
            }}
          />
        </Form.Item>
  
        {selectedPO && (
          <>
            {loadingAmounts ? (
              <Col span={24}>
                <Row>
                  <ContentLoader
                    viewBox="0 0 845 60"
                    foregroundColor="#f5f6f7"
                    backgroundColor="#b1b1b1"
                  >
                    <rect x="0" y="0" rx="8" ry="8" width="120" height="15" />
                    <rect x="0" y="30" rx="8" ry="8" width="415" height="30" />
                    <rect x="425" y="0" rx="8" ry="8" width="120" height="15" />
                    <rect x="425" y="30" rx="8" ry="8" width="415" height="30" />
                  </ContentLoader>
                </Row>
              </Col>
            ) : (
              <>
                <Form.Item
                  name="totalAmount"
                  colProps={{ span: 12 }}
                  validateTrigger="onBlur"
                  label="Valor da requisição"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    size="small"
                    placeholder=""
                    disabled
                    controls={false}
                    style={{ width: "100%" }}
                    parser={formatRealToNumber}
                    formatter={formatNumberToReal}
                  />
                </Form.Item>
                <Form.Item
                  name="remainingAmount"
                  label="Valor restante"
                  colProps={{ span: 12 }}
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    size="small"
                    placeholder=""
                    disabled
                    controls={false}
                    style={{ width: "100%" }}
                    parser={formatRealToNumber}
                    formatter={formatNumberToReal}
                  />
                </Form.Item>
              </>
            )}
          </>
        )}
      </>
    );
  };
  