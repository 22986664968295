import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import '@icts/web/reset.css'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { kcAuthClient } from './Services/keycloak'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ReactKeycloakProvider authClient={kcAuthClient}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ReactKeycloakProvider>
)
