import { color } from "@icts/tokens";
import { formatStringForSearch } from "./format";

export const invoiceStatus = [
    {
        name: 'Aberto',
        tagColor: 'blue',
        color: color.system.blue.pure,
    },
    {
        name: 'Em processamento',
        tagColor: 'yellow',
        color: color.system.yellow.pure,
    },
    {
        name: 'Erro de processamento',
        tagColor: 'red',
        color: color.system.red.pure,
    },
    {
        name: 'Processado com sucesso',
        tagColor: 'green',
        color: color.aliant.secondary.pure,
    },
    {
        name: 'Atrasado',
        tagColor: 'yellow',
        color: color.system.yellow.pure,
    },
    {
        name: 'Pagamento realizado',
        tagColor: 'green',
        color: color.aliant.secondary.pure,
    },
    {
        name: 'Em validação',
        tagColor: 'blue',
        color: color.system.blue.pure,
    },
    {
        name: 'Aguardando autorização para pagamento',
        tagColor: 'yellow',
        color: color.system.yellow.pure,
    },
    {
        name: 'Pedido Negado',
        tagColor: 'red',
        color: color.system.red.pure,
    },
    {
        name: 'Cancelado',
        tagColor: 'red',
        color: color.system.red.pure,
    },
    {
        name: 'Aguardando Financeiro',
        tagColor: 'yellow',
        color: color.system.yellow.pure,
    },
]

export const getStatusInfos = (statusName = '') => {
    const status = invoiceStatus.find(status => formatStringForSearch(status?.name) === formatStringForSearch(statusName))

    if (!status) {
        return {
            name: statusName,
            tagColor: 'red',
            color: color.system.red.pure,
        }
    }

    return status
}