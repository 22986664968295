import styled, { css } from "styled-components";
import { Row, Button, Col } from "@icts/web";
import { color } from "@icts/tokens";

export const Container = styled(Row)`
    padding: 32px 32px 104px 32px;
    background: ${color.system.blue.dark};
`;

export const RegisterInvoiceButton = styled(Button)`
    background: white;
    color: ${color.system.blue.dark};
    border: 1px solid ${color.system.blue.pure};

    &:not(:disabled):hover {
        background: ${color.system.blue.pure};
    }
`;

export const AlertContainer = styled(Col)`
    color: black;
    display: flex;
    max-width: 382px;
    margin-right: 1em;
    border-radius: 8px;
    margin-top: -0.5em;
    background-color: ${color.system.blue.light};

    ${({ hideAlert }) => hideAlert && css`
        display: none;
    `}
`;

export const CustomIcon = styled.div`
    width: 4em;
    margin: auto;
    margin-left: 0.7em;
    margin-right: 0.7em;

    .icon-alerta:before {
        color: ${color.system.yellow.pure};
    }
`;
