import { InvoiceCard } from "../../../../components/InvoiceCard";
import { ModalRegisterInvoiceICTS } from "../../components/Modal/RegisterInvoiceICTS";
import * as S from "./styles";
import { useEffect, useState } from "react";
import { ModalShowInvoice } from "../Modal/ShowInvoice";
import { ModalRegisterInvoiceBAND } from "../Modal/RegisterInvoiceBAND";
import { companyAtom } from "../../../../store";
import { useAtom } from "jotai";
import { api } from "../../../../Services/api";
import ContentLoader from "react-content-loader";
import InfoSVG from "../../../../assets/info.svg";
import { Typography, Row } from "@icts/web";
import { getFilteredBandInvoices } from "@/utils/getFilteredBandInvoices";
const { Title } = Typography;

export const InvoicesList = ({
  usingICTSModalForTaxes,
  setUsingICTSModalForTaxes,
  openRegisterInvoiceModalICTS,
  setOpenRegisterInvoiceModalICTS,
}) => {
  const [company] = useAtom(companyAtom);
  const [loading, setLoading] = useState(false);
  const [invoicesList, setInvoicesList] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const isBandEnv = import.meta.env.VITE_COMPANY_ENV === "BAND";
  const [openSeeInvoiceModal, setOpenSeeInvoiceModal] = useState(false);
  const [openRegisterInvoiceModalBand, setOpenRegisterInvoiceModalBand] = useState(false);

  const handleCallInvoicesAPI = async () => {
    setLoading(true);
    const invoices = await api.getInvoices(company.cnpj);

    setInvoicesList(isBandEnv ? getFilteredBandInvoices(invoices) : invoices);
    setLoading(false);
  }

  useEffect(() => {
    handleCallInvoicesAPI()
  }, [company]);

  if (loading) {
    return (
      <S.InvoicesList>
        <ContentLoader
          viewBox="0 0 1580 300"
          foregroundColor="#f5f6f7"
          backgroundColor="#b1b1b1"
        >
          <rect x="0" y="0" rx="8" ry="8" width="500" height="300" />
          <rect x="540" y="0" rx="8" ry="8" width="500" height="300" />
          <rect x="1080" y="0" rx="8" ry="8" width="500" height="300" />
        </ContentLoader>
      </S.InvoicesList>
    );
  }

  if (!loading && !invoicesList?.length) {
    return (
      <>
        <S.EmptyList>
          <img src={InfoSVG} alt="info icon" />
          <Title>“Você não possui nenhuma nota cadastrada ainda”</Title>
        </S.EmptyList>

        {openRegisterInvoiceModalICTS && (
          <ModalRegisterInvoiceICTS
            handleCallInvoicesAPI={handleCallInvoicesAPI}
            usingICTSModalForTaxes={usingICTSModalForTaxes}
            setUsingICTSModalForTaxes={setUsingICTSModalForTaxes}
            setOpenRegisterInvoiceModal={setOpenRegisterInvoiceModalICTS}
          />
        )}
      </>
    );
  }

  return (
    <>
      <S.InvoicesList>
        {invoicesList?.length > 0 && (
          <Row gutter={[24, 24]}>
            {invoicesList?.map((invoice) => (
              <InvoiceCard
                key={invoice.Id}
                invoice={invoice}
                setSelectedInvoice={setSelectedInvoice}
                setOpenSeeInvoiceModal={setOpenSeeInvoiceModal}
                setOpenRegisterInvoiceModal={setOpenRegisterInvoiceModalBand}
              />
            ))}
          </Row>
        )}

        {openRegisterInvoiceModalBand && (
          <ModalRegisterInvoiceBAND
            invoice={selectedInvoice}
            setSelectedInvoice={setSelectedInvoice}
            handleCallInvoicesAPI={handleCallInvoicesAPI}
            setOpenRegisterInvoiceModal={setOpenRegisterInvoiceModalBand}
          />
        )}

        {openSeeInvoiceModal && (
          <ModalShowInvoice
            invoiceId={selectedInvoice?.Id}
            setOpen={setOpenSeeInvoiceModal}
          />
        )}

        {openRegisterInvoiceModalICTS && (
          <ModalRegisterInvoiceICTS
            handleCallInvoicesAPI={handleCallInvoicesAPI}
            usingICTSModalForTaxes={usingICTSModalForTaxes}
            setUsingICTSModalForTaxes={setUsingICTSModalForTaxes}
            setOpenRegisterInvoiceModal={setOpenRegisterInvoiceModalICTS}
          />
        )}
      </S.InvoicesList>
    </>
  );
};
