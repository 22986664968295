import './Spinner.scss';
import React from 'react';
import Icon from 'icts-icon';

const Spinner = () => (
  <div className="spinner">
    <div className="spinner__wrapper">
      <Icon name="icts" size="lg" />
      <h2 className="value">Carregando...</h2>
    </div>
  </div>
);

export default Spinner;
